import createLucideIcon from '../createLucideIcon';

/**
 * @component @name SquareDashedKanban
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCA3djciIC8+CiAgPHBhdGggZD0iTTEyIDd2NCIgLz4KICA8cGF0aCBkPSJNMTYgN3Y5IiAvPgogIDxwYXRoIGQ9Ik01IDNhMiAyIDAgMCAwLTIgMiIgLz4KICA8cGF0aCBkPSJNOSAzaDEiIC8+CiAgPHBhdGggZD0iTTE0IDNoMSIgLz4KICA8cGF0aCBkPSJNMTkgM2EyIDIgMCAwIDEgMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMSA5djEiIC8+CiAgPHBhdGggZD0iTTIxIDE0djEiIC8+CiAgPHBhdGggZD0iTTIxIDE5YTIgMiAwIDAgMS0yIDIiIC8+CiAgPHBhdGggZD0iTTE0IDIxaDEiIC8+CiAgPHBhdGggZD0iTTkgMjFoMSIgLz4KICA8cGF0aCBkPSJNNSAyMWEyIDIgMCAwIDEtMi0yIiAvPgogIDxwYXRoIGQ9Ik0zIDE0djEiIC8+CiAgPHBhdGggZD0iTTMgOXYxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/square-dashed-kanban
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const SquareDashedKanban = createLucideIcon('SquareDashedKanban', [
  ['path', { d: 'M8 7v7', key: '1x2jlm' }],
  ['path', { d: 'M12 7v4', key: 'xawao1' }],
  ['path', { d: 'M16 7v9', key: '1hp2iy' }],
  ['path', { d: 'M5 3a2 2 0 0 0-2 2', key: 'y57alp' }],
  ['path', { d: 'M9 3h1', key: '1yesri' }],
  ['path', { d: 'M14 3h1', key: '1ec4yj' }],
  ['path', { d: 'M19 3a2 2 0 0 1 2 2', key: '18rm91' }],
  ['path', { d: 'M21 9v1', key: 'mxsmne' }],
  ['path', { d: 'M21 14v1', key: '169vum' }],
  ['path', { d: 'M21 19a2 2 0 0 1-2 2', key: '1j7049' }],
  ['path', { d: 'M14 21h1', key: 'v9vybs' }],
  ['path', { d: 'M9 21h1', key: '15o7lz' }],
  ['path', { d: 'M5 21a2 2 0 0 1-2-2', key: 'sbafld' }],
  ['path', { d: 'M3 14v1', key: 'vnatye' }],
  ['path', { d: 'M3 9v1', key: '1r0deq' }],
]);

export default SquareDashedKanban;
