import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Kanban
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiA1djExIiAvPgogIDxwYXRoIGQ9Ik0xMiA1djYiIC8+CiAgPHBhdGggZD0iTTE4IDV2MTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/kanban
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Kanban = createLucideIcon('Kanban', [
  ['path', { d: 'M6 5v11', key: 'mdvv1e' }],
  ['path', { d: 'M12 5v6', key: '14ar3b' }],
  ['path', { d: 'M18 5v14', key: '7ji314' }],
]);

export default Kanban;
