import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ListVideo
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTJIMyIgLz4KICA8cGF0aCBkPSJNMTYgNkgzIiAvPgogIDxwYXRoIGQ9Ik0xMiAxOEgzIiAvPgogIDxwYXRoIGQ9Im0xNiAxMiA1IDMtNSAzdi02WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/list-video
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ListVideo = createLucideIcon('ListVideo', [
  ['path', { d: 'M12 12H3', key: '18klou' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M12 18H3', key: '11ftsu' }],
  ['path', { d: 'm16 12 5 3-5 3v-6Z', key: 'zpskkp' }],
]);

export default ListVideo;
