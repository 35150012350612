import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MonitorCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTd2NCIgLz4KICA8cGF0aCBkPSJtMTUuMiA0LjktLjktLjQiIC8+CiAgPHBhdGggZD0ibTE1LjIgNy4xLS45LjQiIC8+CiAgPHBhdGggZD0ibTE2LjkgMy4yLS40LS45IiAvPgogIDxwYXRoIGQ9Im0xNi45IDguOC0uNC45IiAvPgogIDxwYXRoIGQ9Im0xOS41IDIuMy0uNC45IiAvPgogIDxwYXRoIGQ9Im0xOS41IDkuNy0uNC0uOSIgLz4KICA8cGF0aCBkPSJtMjEuNyA0LjUtLjkuNCIgLz4KICA8cGF0aCBkPSJtMjEuNyA3LjUtLjktLjQiIC8+CiAgPHBhdGggZD0iTTIyIDEzdjJhMiAyIDAgMCAxLTIgMkg0YTIgMiAwIDAgMS0yLTJWNWEyIDIgMCAwIDEgMi0yaDciIC8+CiAgPHBhdGggZD0iTTggMjFoOCIgLz4KICA8Y2lyY2xlIGN4PSIxOCIgY3k9IjYiIHI9IjMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/monitor-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MonitorCog = createLucideIcon('MonitorCog', [
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'm15.2 4.9-.9-.4', key: '12wd2u' }],
  ['path', { d: 'm15.2 7.1-.9.4', key: '1r2vl7' }],
  ['path', { d: 'm16.9 3.2-.4-.9', key: '3zbo91' }],
  ['path', { d: 'm16.9 8.8-.4.9', key: '1qr2dn' }],
  ['path', { d: 'm19.5 2.3-.4.9', key: '1rjrkq' }],
  ['path', { d: 'm19.5 9.7-.4-.9', key: 'heryx5' }],
  ['path', { d: 'm21.7 4.5-.9.4', key: '17fqt1' }],
  ['path', { d: 'm21.7 7.5-.9-.4', key: '14zyni' }],
  ['path', { d: 'M22 13v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7', key: '1tnzv8' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
  ['circle', { cx: '18', cy: '6', r: '3', key: '1h7g24' }],
]);

export default MonitorCog;
