import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Rat
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgNWMwLTEuNy0xLjMtMy0zLTNzLTMgMS4zLTMgM2MwIC44LjMgMS41LjggMkgxMWMtMy45IDAtNyAzLjEtNyA3YzAgMi4yIDEuOCA0IDQgNCIgLz4KICA8cGF0aCBkPSJNMTYuOCAzLjljLjMtLjMuNi0uNSAxLS43IDEuNS0uNiAzLjMuMSAzLjkgMS42LjYgMS41LS4xIDMuMy0xLjYgMy45bDEuNiAyLjhjLjIuMy4yLjcuMiAxLS4yLjgtLjkgMS4yLTEuNyAxLjEgMCAwLTEuNi0uMy0yLjctLjZIMTdjLTEuNyAwLTMgMS4zLTMgMyIgLz4KICA8cGF0aCBkPSJNMTMuMiAxOGEzIDMgMCAwIDAtMi4yLTUiIC8+CiAgPHBhdGggZD0iTTEzIDIySDRhMiAyIDAgMCAxIDAtNGgxMiIgLz4KICA8cGF0aCBkPSJNMTYgOWguMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/rat
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Rat = createLucideIcon('Rat', [
  [
    'path',
    {
      d: 'M17 5c0-1.7-1.3-3-3-3s-3 1.3-3 3c0 .8.3 1.5.8 2H11c-3.9 0-7 3.1-7 7c0 2.2 1.8 4 4 4',
      key: '1wq71c',
    },
  ],
  [
    'path',
    {
      d: 'M16.8 3.9c.3-.3.6-.5 1-.7 1.5-.6 3.3.1 3.9 1.6.6 1.5-.1 3.3-1.6 3.9l1.6 2.8c.2.3.2.7.2 1-.2.8-.9 1.2-1.7 1.1 0 0-1.6-.3-2.7-.6H17c-1.7 0-3 1.3-3 3',
      key: '1crdmb',
    },
  ],
  ['path', { d: 'M13.2 18a3 3 0 0 0-2.2-5', key: '1ol3lk' }],
  ['path', { d: 'M13 22H4a2 2 0 0 1 0-4h12', key: 'bt3f23' }],
  ['path', { d: 'M16 9h.01', key: '1bdo4e' }],
]);

export default Rat;
