import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ChartBarStacked
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTN2NCIgLz4KICA8cGF0aCBkPSJNMTUgNXY0IiAvPgogIDxwYXRoIGQ9Ik0zIDN2MTZhMiAyIDAgMCAwIDIgMmgxNiIgLz4KICA8cmVjdCB4PSI3IiB5PSIxMyIgd2lkdGg9IjkiIGhlaWdodD0iNCIgcng9IjEiIC8+CiAgPHJlY3QgeD0iNyIgeT0iNSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHJ4PSIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/chart-bar-stacked
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ChartBarStacked = createLucideIcon('ChartBarStacked', [
  ['path', { d: 'M11 13v4', key: 'vyy2rb' }],
  ['path', { d: 'M15 5v4', key: '1gx88a' }],
  ['path', { d: 'M3 3v16a2 2 0 0 0 2 2h16', key: 'c24i48' }],
  ['rect', { x: '7', y: '13', width: '9', height: '4', rx: '1', key: '1iip1u' }],
  ['rect', { x: '7', y: '5', width: '12', height: '4', rx: '1', key: '1anskk' }],
]);

export default ChartBarStacked;
