import createLucideIcon from '../createLucideIcon';

/**
 * @component @name PhilippinePeso
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgMTFINCIgLz4KICA8cGF0aCBkPSJNMjAgN0g0IiAvPgogIDxwYXRoIGQ9Ik03IDIxVjRhMSAxIDAgMCAxIDEtMWg0YTEgMSAwIDAgMSAwIDEySDciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/philippine-peso
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const PhilippinePeso = createLucideIcon('PhilippinePeso', [
  ['path', { d: 'M20 11H4', key: '6ut86h' }],
  ['path', { d: 'M20 7H4', key: 'zbl0bi' }],
  ['path', { d: 'M7 21V4a1 1 0 0 1 1-1h4a1 1 0 0 1 0 12H7', key: '1ana5r' }],
]);

export default PhilippinePeso;
