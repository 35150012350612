import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ToyBrick
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHg9IjMiIHk9IjgiIHJ4PSIxIiAvPgogIDxwYXRoIGQ9Ik0xMCA4VjVjMC0uNi0uNC0xLTEtMUg2YTEgMSAwIDAgMC0xIDF2MyIgLz4KICA8cGF0aCBkPSJNMTkgOFY1YzAtLjYtLjQtMS0xLTFoLTNhMSAxIDAgMCAwLTEgMXYzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/toy-brick
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ToyBrick = createLucideIcon('ToyBrick', [
  ['rect', { width: '18', height: '12', x: '3', y: '8', rx: '1', key: '158fvp' }],
  ['path', { d: 'M10 8V5c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v3', key: 's0042v' }],
  ['path', { d: 'M19 8V5c0-.6-.4-1-1-1h-3a1 1 0 0 0-1 1v3', key: '9wmeh2' }],
]);

export default ToyBrick;
