import createLucideIcon from '../createLucideIcon';

/**
 * @component @name BookDashed
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTdoMiIgLz4KICA8cGF0aCBkPSJNMTIgMjJoMiIgLz4KICA8cGF0aCBkPSJNMTIgMmgyIiAvPgogIDxwYXRoIGQ9Ik0xOCAyMmgxYTEgMSAwIDAgMCAxLTEiIC8+CiAgPHBhdGggZD0iTTE4IDJoMWExIDEgMCAwIDEgMSAxdjEiIC8+CiAgPHBhdGggZD0iTTIwIDE1djJoLTIiIC8+CiAgPHBhdGggZD0iTTIwIDh2MyIgLz4KICA8cGF0aCBkPSJNNCAxMVY5IiAvPgogIDxwYXRoIGQ9Ik00IDE5LjVWMTUiIC8+CiAgPHBhdGggZD0iTTQgNXYtLjVBMi41IDIuNSAwIDAgMSA2LjUgMkg4IiAvPgogIDxwYXRoIGQ9Ik04IDIySDYuNWExIDEgMCAwIDEgMC01SDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/book-dashed
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BookDashed = createLucideIcon('BookDashed', [
  ['path', { d: 'M12 17h2', key: '13u4lk' }],
  ['path', { d: 'M12 22h2', key: 'kn7ki6' }],
  ['path', { d: 'M12 2h2', key: 'cvn524' }],
  ['path', { d: 'M18 22h1a1 1 0 0 0 1-1', key: 'w6gbqz' }],
  ['path', { d: 'M18 2h1a1 1 0 0 1 1 1v1', key: '1vpra5' }],
  ['path', { d: 'M20 15v2h-2', key: 'fph276' }],
  ['path', { d: 'M20 8v3', key: 'deu0bs' }],
  ['path', { d: 'M4 11V9', key: 'v3xsx8' }],
  ['path', { d: 'M4 19.5V15', key: '6gr39e' }],
  ['path', { d: 'M4 5v-.5A2.5 2.5 0 0 1 6.5 2H8', key: 'wywhs9' }],
  ['path', { d: 'M8 22H6.5a1 1 0 0 1 0-5H8', key: '1cu73q' }],
]);

export default BookDashed;
